<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/client"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-avatar"> </i>
          <span class="menu-text">Clients</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-users"></i>
        <span class="menu-text">Employees</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Caregivers</span>
            </span>
          </li>

          <router-link
            to="/caregiver"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Caregivers</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
            to="/otherStaff"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Other Staff</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-clock-2"></i>
        <span class="menu-text">Time Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Time Management</span>
            </span>
          </li>

          <router-link
            to="/timeKeeping"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Time Tracker</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/timeEntry"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Admin Level 2' ||
              GetAuthenticatedUser.userType == 'JFS' ||
              GetAuthenticatedUser.userType == 'Super Admin'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Time Entry</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- <router-link
      to="/payRoll"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-coins"></i>
          <span class="menu-text">PayRoll Center</span>
        </a>
      </li>
    </router-link> -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
      v-if="
        GetAuthenticatedUser.userType == 'Admin Level 2' ||
        GetAuthenticatedUser.userType == 'JFS' ||
        GetAuthenticatedUser.userType == 'Home Health' ||
        GetAuthenticatedUser.userType == 'Super Admin'
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-piggy-bank"></i>
        <span class="menu-text">Payroll</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">payroll</span>
            </span>
          </li>

          <router-link
            to="/currentpayroll"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">View Current Payroll</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/payroll"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType != 'JFS' &&
              GetAuthenticatedUser.userType != 'Home Health'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Process Payroll</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/draftpayroll"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType != 'JFS' &&
              GetAuthenticatedUser.userType != 'Home Health'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Saved Payroll Batches</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-chat"></i>
        <span class="menu-text">Message Center</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Message Center</span>
            </span>
          </li>
          <router-link
            to="/Email"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="GetAuthenticatedUser.userType == 'Super Admin'"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Send Email</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/EmailTemplate"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="GetAuthenticatedUser.userType == 'Super Admin'"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Templates</span>
              </a>
            </li>
          </router-link>
          <!-- 
          <router-link
            to="/incoming"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Incoming Message</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/outgoing"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Outgoing Message</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <!-- <router-link
      to="/message"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-chat"></i>
          <span class="menu-text">Message Center</span>
        </a>
      </li>
    </router-link> -->
    <!-- <router-link
      to="/auth"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Auth Maintenance</span>
        </a>
      </li>
    </router-link> -->
    <router-link
      to="/clientreport"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-analytics"></i>
          <span class="menu-text">Analytics & Reports</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-settings-1"></i>
        <span class="menu-text">Control Panel</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Control Panel</span>
            </span>
          </li>

          <router-link
            to="/Payer"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Payors</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
            to="/CaseWorker"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Case Workers</span>
              </a>
            </li>
          </router-link> -->
          <router-link
            to="/vendorCode"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Vendor Code</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/subCode"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">SubCode/Service</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/user"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Users</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/import"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Import</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/bulkAuthorization"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Authorization Manager</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-wizard/wizard'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-settings-1"></i>
        <span class="menu-text">EVV</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">EVV</span>
            </span>
          </li>

<router-link
            to="/evvform"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">EVV Forms</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/clockin"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Clock In</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/evvformRe"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              GetAuthenticatedUser.userType == 'Super Admin' ||
              GetAuthenticatedUser.userType == 'Admin Level 1'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">EVV Form Re..</span>
              </a>
            </li>
          </router-link>
        
        </ul>
      </div>
    </li>
    <span class="menu-item">
      <a :href="href" class="menu-link" @click="Logout">
        <i class="menu-icon flaticon-logout"></i>
        <span class="menu-text">Logout</span>
      </a>
    </span>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["GetAuthenticatedUser"]),
  },
  mounted() {},
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    Logout() {
      this.$store.dispatch("LogoutUser").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
