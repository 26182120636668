<template>
  <ul class="menu-nav ">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
     
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" v-if="isActive == false" @click="navigate">
          <span class="menu-text"> Dashboard </span>
        </a>
        <h3 v-else> <b> Hello, {{GetAuthenticatedUser.userName}}  </b></h3>
      </li>
    </router-link>   
    
   
    
  </ul>
</template>

<script>

import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
   computed: {
    ...mapGetters([
      "GetAuthenticatedUser", 
    ]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
